const $ = require('jquery/dist/jquery');
const $body = $('body');

// any CSS you import will output into a single css file (app.css in this case)
import './css/style.css';

/* -- Tracking -- */

$.ajax(BASE_URL + '/tracking/', {
    type: "POST",
    data: {
        'url': window.location.href
    },
    success: function (result) {
    }
});
